@import url("https://fonts.googleapis.com/css2?family=Imprima&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Imprima", sans-serif;
}

.ant-steps-item-icon {
  background-color: #552130 !important;
  color: #fff !important;
  border: #552130 !important;
}

.ant-steps-item-icon span {
  color: #fff !important;
}

.ant-steps-item-tail::after {
  background-color: #552130 !important;
}
